<template>
  <div>
    <div class="pititle">
      <div class="zhidingsearch">
        <el-form>
          <div class="litop">
            <el-row>
              <el-col :span="5">
                <div class="grid-content bg-purple">
                  <el-form-item label="标准名称">
                    <el-input v-model="name" placeholder="请输入您的标准名称" clearable @keyup.enter.native="search()"></el-input>
                  </el-form-item>
                </div>
              </el-col>
              <el-col :span="5">
                <div class="grid-content bg-purple">
                  <el-form-item label="备案号">
                    <el-input v-model="number" placeholder="请输入您的备案号" clearable @keyup.enter.native="search()"></el-input>
                  </el-form-item>
                </div>
              </el-col>
              <el-col :span="4">
                <div class="grid-content bg-purple">
                  <el-button type="primary" @click="search">查询</el-button>
                  <el-button type="info" @click="qingkong">清空</el-button>
                  <el-button type="primary" @click="addFilings">新增</el-button>
                </div>
              </el-col>
            </el-row>
          </div>
        </el-form>
      </div>
      <div class="piconetn">
        <!-- 表格 -->
        <div class="pitable">
          <el-table :data="tableData" :row-class-name="tabRowClassName" style="width: 100%"
            :header-cell-style="{ background: '#3082E2', color: '#FFF' }">
            <el-table-column type="selection" width="55">
            </el-table-column>
            <el-table-column label="序号" type="index">
            </el-table-column>
            <el-table-column label="标准名称" prop="proName" align="center">
              <template slot-scope="{row}">
                <!-- <router-link :to="gotoStep(row)" class="linkto"> -->
                  <span @click="gotoStep(row)" class="linkto">{{ row.project_names }}</span>
                <!-- </router-link> -->
              </template>
            </el-table-column>
            <el-table-column label="备案号" prop="number" align="center">
            </el-table-column>
            <el-table-column label="申请日期" prop="created_at" align="center" width="130px">
              <template slot-scope="{ row }">
                <span>{{
                  row.created_at | formatDate("yyyy-MM-dd")
                }}</span>
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center" width="200px">
              <template slot-scope="{ row }">
                <el-button type="text" @click="handleUpdate(row)">编辑</el-button>
                <el-button type="text" @click="handleDelete(row)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <!-- 分页 -->
        <div class="pageTotal">
          <div class="block">
            <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
              :current-page="page" layout="prev, pager, next" :total="totalCount" :page-size="limit">
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
    <div>
      <AddFillingsDlg ref="addFillingsRef" :rowData="rowData"></AddFillingsDlg>
    </div>
  </div>
</template>
<script>
import { standardFilingList, standardFilingDel } from "../../../../api/project";
import AddFillingsDlg from "./components/addFillingsDlg.vue";

export default {
  components: { AddFillingsDlg },
  filters: {
    formatDate: function (timestamp, fmt) {
      if (fmt == undefined || fmt == "" || fmt == null) {
        fmt = "yyyy-MM-dd ";
      }
      if (!timestamp) {
        return "";
      }
      let date = new Date(timestamp);
      // 第一步先替换年份 因为年份跟其他不一样是四位
      // let pat =
      if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, date.getFullYear());
      }
      // 第二步匹配其他，然后替换
      let obj = {
        "M+": date.getMonth() + 1,
        "d+": date.getDate(),
        "h+": date.getHours(),
        "m+": date.getMinutes(),
        "s+": date.getSeconds(),
      };
      for (let key in obj) {
        let pat = `(${key})`;
        if (new RegExp(pat).test(fmt)) {
          let str = obj[key] + "";
          // RegExp.$1  MM dd hh mm ss贪婪匹配
          fmt = fmt.replace(RegExp.$1, ("00" + str).substr(-2));
        }
      }
      return fmt;
    },
  },
  data() {
    return {
      //选中的表格数据
      selectedProjects: [],
      form: {},
      page: 1,
      limit: 10,
      totalCount: 0,
      totalPage: 1,
      tableData: [],
      token: '',
      firstUnit: '',
      proName: '',
      addForm: null,
      rowData: null,
      isAdd: false,
      name: "",
      number: ""
    };
  },
  mounted() {
    // this.getList();
    this.getToken();
    this.getList();
  },
  methods: {
    getToken() {
      this.token = this.$store.state.token; //获取cookie字符串
      console.log(this.token);
    },
    tabRowClassName({ rowIndex }) {
      let index = rowIndex + 1;
      if (index % 2 == 0) {
        return 'warning-row';
      }
    },
    //获得表格数据
    getList() {
      let data = {
        limit: this.limit,
        page: this.page,
        token: this.token,
        number: this.number,
        name: this.name
      };
      standardFilingList(data).then(rec => {
        console.log('lists', rec);
        this.tableData = rec.data.data;
        this.limit = Number(rec.data.per_page);
        this.page = rec.data.current_page;
        this.totalCount = rec.data.total;
        this.totalPage = rec.data.total;
        console.log(rec);
      });
    },
    //搜索
    search() {
      this.page = 1;
      this.getList();
    },
    //清空
    qingkong() {
      this.name = ""
      this.number = ""
      this.getList();
    },
    //新增
    addFilings() {
      this.$refs.addFillingsRef.addForm = {
        number: "",
        project: [],
        file_obj: null
      }
      this.$refs.addFillingsRef.public_title = '新增备案'
      this.$refs.addFillingsRef.addDialogVisible = true
      this.$refs.addFillingsRef.queryStandards()
    },
    // 分页
    handleSizeChange(page) {
      this.limit = page;
      this.getList();
      //console.log(`每页 ${page} 条`)
    },
    handleCurrentChange(page) {
      //console.log(`当前页: ${page}`)
      this.page = page;
      this.getList();
    },
    //表格多选
    selected_change(val) {
      this.selectedProjects = val;
    },
    gotoStep(row) {
      window.open(row.file)
    },
    exportExcel() {
      let url = 'https://greenapi.cdmp.tech/api/standard/export?';
      let idarr = this.selectedProjects.map((item) => {
        return item.id;
      });
      let ids = idarr.toString();
      url += 'token=' + this.$store.state.token;
      url += '&ids=' + ids;
      window.open(url, 'blank');
      console.log(url);
    },
    handleUpdate(row) {
      this.rowData = row
      this.$refs.addFillingsRef.addDialogVisible = true
      this.$refs.addFillingsRef.queryStandards()
      this.$refs.addFillingsRef.public_title = '修改备案'
    },
    handleDelete(row) {
      this.$confirm('是否确认删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let data = {
          token: this.token,
          id: row.id
        }
        standardFilingDel(data).then(rec => {
          // debugger
          console.log('返回的结果', rec)
          if (rec.code == 200) {
            this.$message({
              type: "success",
              message: rec.message
            })
            this.getList()
          }
        })
      }).catch(() => {
      });
    }
  },
};
</script>
<style scoped>
/deep/.el-button--primary {
  background-color: #3082e2;
  border-color: #3082e2;
}

/deep/.el-button--primary:hover {
  background-color: #409EFF;
  border-color: #409EFF;
}

/deep/.el-input__suffix {
  right: 43px;
  transition: all .3s;
  border-left: 1px solid #c0c4cc;
}

/deep/.el-table__row .el-table__row--striped {
  background-color: rgba(48, 130, 226, 0.1);
}

/deep/ .el-table th.el-table__cell>.cell {
  min-width: 100px;
}

/deep/ .el-table th.el-table__cell.is-leaf,
/deep/ .el-table td.el-table__cell {
  border-bottom: 0;
}

/deep/.el-form-item__label {
  font-family: Adobe Heiti Std;
  color: #2D2D2D;
}

/deep/ .litop .el-input {
  width: 245px;
}

/deep/ .litop .el-input__inner {
  width: 204px;
}

/deep/ .el-table th.el-table__cell>.cell {
  font-size: 16px;
}

/deep/.el-table .warning-row {
  background: rgba(48, 130, 226, 0.1);
}

.pitable {
  /*height: 60vh;*/
}

.litop {
  margin: 20px 24px;
}

.litop span {
  color: #3086fb;
  margin-left: 10px;
  line-height: 40px;
}

.pageTotal {
  display: flex;
  flex-direction: row-reverse;
  margin: 30px 0;
}

.prbtn {
  display: flex;
  flex-direction: row-reverse;
}

.linkto {
  text-decoration: none;
  color: #3082E2;
}

.linkto>span:hover {
  color: #84b5ec;
}

/* 新改 */
.zhidingsearch {
  background-color: #ffffff;
  padding: 1px 15px;
  margin: 20px 0 10px 0;
}

.piconetn {
  background-color: #ffffff;
  padding: 20px;
  box-shadow: 0px 3px 9px 1px #EEEEEE;
}
</style>
  