<template>
    <div>
        <el-dialog :visible.sync="addDialogVisible" :title="public_title" :close-on-click-modal="false"
            @close="cancleEdit()" custom-class="add_dialog">
            <el-form :model="addForm" ref="addForm">
                <div class="form_line">
                    <el-form-item label="备案号" prop="number"
                        :rules="[{ required: true, message: '请输入备案号', trigger: 'blur' }]">
                        <el-input placeholder="请输入备案号" v-model="addForm.number"></el-input>
                    </el-form-item>
                </div>
                <div class="form_line">
                    <el-form-item label="标准名称" prop="project"
                        :rules="[{ required: true, message: '请选择标准名称', trigger: 'change' }]">
                        <el-select v-model="addForm.project" filterable multiple placeholder="请选择标准名称"
                            @change="changeProject">
                            <el-option v-for="item in standardList" :key="item.id" :label="item.proName" :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </div>
                <div class="form_line">
                    <el-form-item label="上传文件" prop="project" :rules="[{ required: true, message: '请上传文件', trigger: 'change' }]">
                        <upload-file ref="uploadimg" v-model="addForm.file_obj" :fileType="fileType"
                            @standard-data="getFileData"></upload-file>
                    </el-form-item>
                </div>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="cancleEdit()">取 消</el-button>
                <el-button type="success" icon="el-icon-position" @click="submitEdit('addForm')">提 交</el-button>
            </div>
        </el-dialog>
    </div>
</template>
  
<script>
import { standardSelect, standardFilingsAdd, standardFilingsEdit } from "../../../../../api/project"
import uploadFile from "../../technicalReview/components/uploadFile.vue"
export default {
    components: { uploadFile },
    name: 'formhead',
    props: {
        rowData: {
            type: Object
        }
    },
    data() {
        return {
            addDialogVisible: false,
            addForm: {
                number: "",
                project: [],
                file_obj: null
            },
            public_title: '新增备案',
            fileType: 'fileWord',
            standardList: [],
            file_obj: null,
            token: '',
            isSelect: false
        }
    },
    mounted() {
        this.token = this.$store.state.token
    },
    methods: {
        queryStandards() {
            standardSelect({ token: this.$store.state.token }).then(rec => {
                // debugger
                this.$refs.uploadimg.wordList = []
                console.log('返回的结果', rec)
                this.standardList = rec.data
                this.$nextTick(function () {
                    console.log(this.rowData, '行内数据')
                    if (this.rowData != null && this.public_title === '修改备案') {
                        this.addForm.id = this.rowData.id;
                        this.addForm.number = this.rowData.number
                        this.addForm.project = this.rowData.project_ids_array
                        let list = [{ name: this.rowData.file_name, url: this.rowData.file }]
                        // this.file_obj = {name:this.rowData.file_name}
                        this.$refs.uploadimg.wordList = list
                    }
                })
            })
        },
        cancleEdit() {
            this.addDialogVisible = false
            this.$refs.addForm.resetFields();
        },
        getFileData(obj) {
            this.file_obj = obj.data
            this.addForm.file_obj = obj.data
        },
        changeProject() {
            // console.log('触发')
            this.isSelect = true
        },
        //提交
        submitEdit(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    // console.log(this.addForm.id, '打印提交的表单数据')
                    this.addForm.token = this.token;
                    if (this.addForm.id === undefined || this.addForm.id === '') {
                        //新增
                        let project_names = []
                        for (let i in this.addForm.project) {
                            for (let j in this.standardList) {
                                if (this.addForm.project[i] === this.standardList[j].id) {
                                    project_names.push(this.standardList[j].proName)
                                }
                            }
                        }
                        console.log(project_names, '标准名称')
                        let data = {
                            ...this.addForm,
                            file: this.file_obj.url, //文件的url
                            file_name: this.file_obj.name,
                            project_names: project_names.join(","),
                            project_ids: this.addForm.project.join(",")
                        }
                        //新增
                        standardFilingsAdd(data).then(rec => {
                            // debugger
                            console.log('返回的结果', rec)
                            if (rec.code == 200) {
                                this.$message({
                                    type: "success",
                                    message: rec.message
                                })
                                this.addDialogVisible = false
                                this.$parent.getList()
                            }
                        })
                    } else if (this.addForm.id != '') {
                        //修改
                        let project_names = []
                        for (let i in this.addForm.project) {
                            for (let j in this.standardList) {
                                if (this.addForm.project[i] === this.standardList[j].id) {
                                    project_names.push(this.standardList[j].proName)
                                }
                            }
                        }
                        // console.log(this.rowData)
                        //修改
                        let data = {
                            ...this.addForm,
                            file: this.rowData === null ? this.file_obj.url : this.rowData.file, 
                            file_name: this.rowData === null ? this.file_obj.name : this.rowData.file_name,
                            project_names: project_names.join(","),
                            project_ids: this.addForm.project.join(",")
                        }
                        // console.log(data, '入参')
                        standardFilingsEdit(data).then(rec => {
                            // debugger
                            console.log('返回的结果', rec)
                            if (rec.code == 200) {
                                this.$message({
                                    type: "success",
                                    message: rec.message
                                })
                                this.addDialogVisible = false
                                this.$parent.getList()
                            }
                        })
                    }
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
    }
}
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/deep/.el-button--primary {
    background-color: #3082e2;
    border-color: #3082e2;
}

/deep/.el-button--primary:hover {
    background-color: #409EFF;
    border-color: #409EFF;
}

/deep/.el-input__suffix {
    right: 43px;
    transition: all .3s;
    border-left: 1px solid #c0c4cc;
}

/deep/.el-table__row .el-table__row--striped {
    background-color: rgba(48, 130, 226, 0.1);
}

/deep/ .el-table th.el-table__cell>.cell {
    min-width: 100px;
}

/deep/ .el-table th.el-table__cell.is-leaf,
/deep/ .el-table td.el-table__cell {
    border-bottom: 0;
}

/deep/.el-form-item__label {
    font-family: Adobe Heiti Std;
    color: #2D2D2D;
}

/deep/ .litop .el-input {
    width: 245px;
}

/deep/ .litop .el-input__inner {
    width: 204px;
}

/deep/ .el-table th.el-table__cell>.cell {
    font-size: 16px;
}

/deep/.el-table .warning-row {
    background: rgba(48, 130, 226, 0.1);
}

/deep/ .el-link.el-link--primary {
    padding-right: 30px !important;
}

.pitable {
    /*height: 60vh;*/
}

.litop {
    margin: 20px 24px;
}

.litop span {
    color: #3086fb;
    margin-left: 10px;
    line-height: 40px;
}

.pageTotal {
    display: flex;
    flex-direction: row-reverse;
    margin: 30px 0;
}

.prbtn {
    display: flex;
    flex-direction: row-reverse;
}

.linkto {
    text-decoration: none;
    color: #3082E2;
}

.linkto>span:hover {
    color: #84b5ec;
}

.piconetn {
    background-color: #ffffff;
    padding: 20px;
    box-shadow: 0px 3px 9px 1px #EEEEEE;
}

.form_line {
    /* display: flex; */
}

.form_line .el-form-item {
    width: 90%;
    margin: 0 16px 24px 0;
}

.form_line .el-form-item .el-input,
.el-select {
    width: 80%;
}

.plus_add {
    margin: 20px 0 20px 0;
}

.agree {
    margin-left: 18px;
}

/deep/ .add_dialog {
    width: 700px;
}

/deep/ .add_dialog .el-form-item__label {
    text-align: left;
    width: 90px;
}

/deep/ .add_dialog .el-form-item__error {
    left: 89px;
}

/deep/ .preview_dialog {
    width: 500px;
}

.preview_dialog .img_top {
    display: flex;
    justify-content: center;
    align-items: center;
}

/* .preview_dialog .prev_img {
      vertical-align: middle;
      width: 210px;
      height: 210px;
  } */

/deep/ .preview_dialog .el-link--inner {
    color: #0099fc;
}

/* .qrcode_img {
      vertical-align: middle;
      width: 50px;
      height: 50px;
  } */

/deep/ .news_dialog {
    width: 70%;
}

/deep/ .news_dialog .el-dialog__header {
    text-align: center;
}

/deep/ .el-step__head.is-finish,
/deep/ .el-step__head.is-process {
    width: 320px;
}

/deep/ .big_code img {
    vertical-align: middle;
    width: 210px;
    height: 210px;
    margin-bottom: 11px;
}
</style>
  