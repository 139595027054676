<template>
    <div>
        <div class="uploadConiter">
            <div v-if="fileType === 'image'">
                <el-upload action="" accept=".jpg,.png" :limit="1" :auto-upload="false" :file-list="fileList"
                    list-type="picture-card" :on-remove="handleRemove" :on-change="handleChange"
                    :http-request="uploadFileEvent" :on-preview="handlePictureCardPreview" :class="objClass">
                    <i class="el-icon-plus"></i>
                </el-upload>
                <el-dialog append-to-body :visible.sync="dialogVisible" width="500px">
                    <img :src="dialogImageUrl" alt="" class="preview_img">
                </el-dialog>
            </div>
            <div v-if="fileType === 'fileWord'">
                <el-upload action="" accept=".pdf,.docx,.doc" :limit="3" multiple :auto-upload="false" :file-list="wordList"
                    :on-remove="handleRemove" :on-change="handleChange" :http-request="uploadFileEvent"
                    :show-file-list="isShow" :on-preview="handlePictureCardPreview">
                    <el-button size="small" type="primary">点击上传</el-button>
                </el-upload>
            </div>
            <div v-if="fileType === 'all'">
                <el-upload action="" accept=".jpg,.png,.jpeg" :limit="1" multiple :auto-upload="false"
                    :show-file-list="false" :file-list="wordList" :on-remove="handleRemove" :on-change="handleChange"
                    :http-request="uploadFileEvent" :on-preview="handlePictureCardPreview">
                    <div class="uploadAll">
                        <el-input v-model="licenseName" placeholder="营业执照扫描" readonly
                            :class="licenseName != '' ? '' : 'license_ipt'"></el-input>
                        <el-button size="small" type="primary" style="width:80px;">点击上传</el-button>
                    </div>
                </el-upload>
                <el-button size="small" type="danger" v-if="licenseName != ''" @click="clearSel"
                    class="clearbtn">清除选择</el-button>
            </div>
            <div v-if="fileType === undefined">
                <el-upload class="upload-demo" action="" multiple accept=".mp4" :on-exceed="handleExceed"
                    :file-list="vedioList" :http-request="uploadFileEvent">
                    <el-button size="small" type="primary" icon="el-icon-upload2">点击上传</el-button>
                </el-upload>
            </div>
        </div>
    </div>
</template>  
<script>
import { enterpriseUpload } from "../../../../../api/enterprise";
export default {
    name: 'uploadfile',
    // inject: ["newData"],
    data() {
        return {
            dialogVisible: false,
            dialogImageUrl: "",
            vedioList: [],
            fileList: [],
            wordList: [],
            objClass: {
                upLoadShow: true,
                upLoadHide: false
            },
            licenseName: ''
        }
    },
    props: {
        fileType: {
            type: String
        },
        reserveData: {
            type: Object
        },
        isShow: {
            type: Boolean
        }
        // license: {
        //     type: String
        // }
    },
    created() {
    },
    mounted() {
        console.log('打印接收的值', this.reserveData)
        this.fileList = []
    },
    methods: {
        handleChange(file, fileList) {
            // console.log(file, '进入组件的文件打印')
            this.licenseName = file.name;
            //隐藏
            this.objClass.upLoadHide = true;
            this.objClass.upLoadShow = false;
            this.uploadFileEvent(file, 'image')
        },
        handlePictureCardPreview(file) {
            this.dialogImageUrl = file.url;
            this.dialogVisible = true;
        },
        handleRemove() {
            //显示
            this.objClass.upLoadHide = false;
            this.objClass.upLoadShow = true;
        },
        handleExceed(file, fileList) {
            console.log(file, fileList);
        },
        preUpload() {

        },
        //上传请求接口
        uploadFileEvent(file, type) {
            console.log(file, '文件请求开始')
            const formData = new FormData();
            // 向 formData 对象中添加文件
            if (type === 'image') {
                //上传图片
                formData.append("file", file.raw);
            } else if (type === undefined) {
                //上传视频
                formData.append("file", file.file);
            } else if (type === "fileWord" || type === "all") {
                formData.append("file", file.raw);
            }
            // console.log(type, '文件视频')
            enterpriseUpload(formData)
                .then((res) => {
                    console.log('打印返回的数据', res)
                    if (res.code == 200) {
                        this.$message({
                            message: "上传成功",
                            type: "success",
                        });
                    }
                    if (type === undefined) {
                        this.vedioList.push({ name: res.data.name, url: res.data.url })
                    } else if (type === 'image') {
                        this.vedioList = []
                        this.fileList.push({ name: res.data.name, url: res.data.url })
                    }

                    let fileData = {
                        photo: res.data.url,
                        files: this.vedioList
                    }

                    this.$emit('reserve-data', fileData);
                    //传给标准文件的
                    res.fileList = this.fileList
                    this.wordList = res.fileList
                    let standardFiles = { ...res }
                    this.$emit('standard-data', standardFiles)
                })
                .catch((err) => {
                    console.log("err", err);
                });
        },
        //清除选择
        clearSel() {
            for (let i in this.wordList) {
                if (this.licenseName === this.wordList[i].name) {
                    this.wordList.splice(i, 1)
                }
            }
            this.licenseName = ""
        }
    },
}
</script>
<style scoped>
.el-dialog__body .preview_img {
    display: block;
    width: 220px;
    height: 220px;
    margin: 0 auto;
}

/deep/ .el-upload .el-upload-dragger {
    width: 80%;
    height: 80%;
}

/deep/ .upLoadShow .el-upload {
    width: 108px;
    height: 108px;
    line-height: 106px;
}

/deep/ .upLoadHide .el-upload-list--picture-card .el-upload-list__item {
    width: 108px;
    height: 108px;
    line-height: 106px;
}

/deep/ .upLoadHide .el-upload {
    display: none !important;
}

/deep/ .uploadConiter .license_ipt {
    width: 260px;
}

/deep/ .uploadConiter .clearbtn {
    position: absolute;
    right: -78px;
    top: 0;
    width: 77px;
    height: 40px;
}
</style>
<style scoped lang="scss">
.uploadConiter {
    .uploadAll {
        display: flex;
        position: relative;
    }
}
</style>